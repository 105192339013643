<template>
  <div>
    <!--  总体分两块-->
    <div style="width: 100%;display: flex">
      <!--材料选择-->
      <div style="width: 45%;margin-right: 5%">
        <!--  材料名称-->
        <div style="display: flex">
          <el-input size="small" style="width: 20%;margin-right: 5%" v-model="search_equiment_material_name"
                    placeholder="请输入材料名称" @input="get_equiment_material_name"/>
        </div>
        <!--  材料列表-->
        <div>
          <el-table :data="equiment_materials" style="width: 100%">
            <el-table-column prop="materialName" label="材料名称" width="180"/>
            <el-table-column prop="materialModel" label="规格型号" width="230"/>
            <el-table-column prop="materialUnit" label="计量单位" width="230"/>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button type="danger" @click="select_equiment_material(scope.row)"> 选择</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--  分页-->
        <div v-if="first" style="float: right;margin-top: 20px;">
          <el-pagination v-model:currentPage="pagenum"
                         :small="true"
                         background layout="prev, pager, next" :total="totals">
          </el-pagination>
        </div>
        <div v-if="!first" style="float: right;margin-top: 20px;">
          <el-pagination v-model:currentPage="pagenum1"
                         :small="true"
                         background layout="prev, pager, next" :total="totals1">
          </el-pagination>
        </div>
      </div>
      <!--供应单位选择-->
      <div style="width: 45%;height: 80vh">
        <!--供应单位名称-->
        <div style="display: flex">
          <el-input size="small" style="width: 15%;margin-right: 5%" v-model="search_source_name"
                    placeholder="请输入单位名称" @input="get_all_source"/>
          <el-select v-model="source_select" clearable class="m-2" placeholder="请选择供应单位\生产厂家" size="small"
                     style="width: 25%" @change="get_all_source">
            <el-option
                v-for="item in source_selects"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </div>
        <!--  单位列表-->
        <div>
          <el-table :data="equiment_sources" style="width: 100%">
            <el-table-column prop="sourceName" label="单位名称" width="180"/>
            <el-table-column prop="a" label="类别" width="280">
              <template #default="scope">
                <el-tag type="success" v-if="scope.row.flag === '0'">供应单位</el-tag>
                <el-tag v-if="scope.row.flag === '1'">生产厂家</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button type="danger" @click="select_equiment_source(scope.row)"> 选择</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--  分页-->
        <div style="float: right;margin-top: 20px;">
          <el-pagination v-model:currentPage="pagenum3"
                         :small="true"
                         background layout="prev, pager, next" :total="totals3">
          </el-pagination>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 0.2%">
      <el-tag style="width: 8%">材料名称</el-tag>
      <el-tag style="width: 8%">规格型号</el-tag>
      <el-tag style="width: 8%">计量单位</el-tag>
      <el-tag style="width: 8%">供应单位</el-tag>
      <el-tag style="width: 8%">生产厂家</el-tag>
    </div>
    <div style="display:flex;">
      <el-tag style="width: 8%">{{ select_materialName }}</el-tag>
      <el-tag style="width: 8%">{{ select_materialModel }}</el-tag>
      <el-tag style="width: 8%">{{ select_materialUnit }}</el-tag>
      <el-tag style="width: 8%">{{ select_source_gy }}</el-tag>
      <el-tag style="width: 8%">{{ select_source_sc }}</el-tag>
      <el-input v-model="material_code" style="width: 20%" size="small" placeholder="请输入原厂配件代码"/>
      <el-select v-if="GET_BDST === '21,56'" v-model="bdst" class="m-2" placeholder="请选择工区" size="small"
                 style="width: 15%">
        <el-option
            v-for="item in bdsts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      <el-button type="primary" size="small" style="margin-left: 5%" @click="save_qrcode">添加</el-button>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";

export default {
  name: "equiment_storehouse",
  computed: {
    ...mapGetters(['GET_BDST'])
  },
  inject: ['reload'],
  data() {
    return {
      material_code: '',
      search_equiment_material_name: '',
      equiment_materials: [],
      first: true,
      pagenum: 1,
      totals: 0,
      pagenum1: 1,
      totals1: 0,
      pagenum3: 1,
      totals3: 0,
      pagenum4: 1,
      totals4: 0,
      source_select: '',
      source_selects: [
        {
          value: '0',
          label: '供应单位'
        },
        {
          value: '1',
          label: '生产厂家'
        }
      ],
      search_source_name: '',
      equiment_sources: [],
      select_materialName: '',
      select_materialModel: '',
      select_materialUnit: '',
      select_materialCess: '',
      select_materialMoney: '',
      select_source_gy: '',
      select_source_sc: '',
      bdst: '',
      bdsts: [
        {
          value: '21',
          label: '二标一工区'
        },
        {
          value: '56',
          label: '五标六工区'
        }
      ]
    }
  },
  created() {
    this.get_all_material()
    this.get_all_source()
  },
  methods: {
    get_all_material() {
      this.axios.post('/equimentMaterial/getAllEquimentMaterial', (response) => {
        this.equiment_materials = response.obj.records
        this.totals = response.obj.pages * 10
      }, {
        pageCount: 1
      })
    },
    get_all_source() {
      this.axios.post('/equimentSource/getAllSource', (response) => {
        this.equiment_sources = response.obj.records
        this.totals3 = response.obj.pages * 10
      }, {
        flag: this.source_select,
        source_name: this.search_source_name,
        pageCount: 1
      })
    },
    get_equiment_material_name() {
      this.first = this.search_equiment_material_name === '';
      this.axios.post('/equimentMaterial/getAllEquimentMaterialByName', (response) => {
        this.equiment_materials = response.obj.records
        this.totals1 = response.obj.pages * 10
      }, {
        name: this.search_equiment_material_name,
        pageCount: 1
      })
    },
    select_equiment_material(item) {
      console.log(item)
      this.select_materialName = item.materialName
      this.select_materialModel = item.materialModel
      this.select_materialUnit = item.materialUnit
      this.select_materialMoney = item.materialMoney
      this.select_materialCess = item.materialCess
    },
    select_equiment_source(item) {
      if (item.flag === '0') {
        this.select_source_gy = item.sourceName
      }
      if (item.flag === '1') {
        this.select_source_sc = item.sourceName
      }
    },
    save_qrcode() {
      // if (this.GET_BDST === '1') {
      //   ElMessage.error('请切换到工区的账号进行添加!')
      // } else
      if (this.GET_BDST !== '21,56') {
        this.bdst = this.GET_BDST
      }
      if (this.bdst === '' || this.select_materialName === ''|| this.select_materialModel === ''|| this.select_materialUnit === ''
      || this.select_source_gy === ''|| this.select_source_sc === ''|| this.material_code === '') {
        ElMessage.error('内容不完整，请补充完整!')
      } else {
        this.axios.post('/equimentQrcode/saveEquimentQrcode', (response) => {
          if (response.obj) {
            ElMessage({
              message: '添加成功',
              type: 'success'
            })
            this.reload()
          } else {
            ElMessage.error('添加失败，请联系科信部!')
          }
        }, {
          bdst: this.bdst,
          material_name: this.select_materialName,
          material_model: this.select_materialModel,
          material_unit: this.select_materialUnit,
          equiment_source: this.select_source_gy,
          equiment_manu: this.select_source_sc,
          material_code: this.material_code,
          material_money: this.select_materialMoney,
          material_cess: this.select_materialCess
        })
      }
    }
  },
  watch: {
    pagenum() {
      this.axios.post('/equimentMaterial/getAllEquimentMaterial', (response) => {
        this.equiment_materials = response.obj.records
        this.totals = response.obj.pages * 10
      }, {
        pageCount: this.pagenum
      })
    },
    pagenum1() {
      this.axios.post('/equimentMaterial/getAllEquimentMaterialByName', (response) => {
        this.equiment_materials = response.obj.records
        this.totals1 = response.obj.pages * 10
      }, {
        name: this.search_equiment_material_name,
        pageCount: this.pagenum1
      })
    },
    pagenum3() {
      this.axios.post('/equimentSource/getAllSource', (response) => {
        this.equiment_sources = response.obj.records
        this.totals3 = response.obj.pages * 10
      }, {
        flag: this.source_select,
        source_name: this.search_source_name,
        pageCount: this.pagenum3
      })
    }
  }
}
</script>

<style scoped>

</style>
