<template>
    <div>
        <!--刷新按钮-->
        <div>
            <el-button type="danger" size="small" @click="gte_ware_qrcode">刷新</el-button>
            <el-button style="margin-left: 5%" type="primary" size="small" @click="getPrienter">连接打印机</el-button>
        </div>
        <!--  入库二维码-->
        <div>
            <el-table :data="ware_qrcodes" style="width: 100%">
                <el-table-column prop="equipmentName" label="设备名称" width="180"/>
                <el-table-column prop="equipmentModel" label="设备型号" width="100"/>
                <el-table-column prop="materialName" label="配件名称" width="100"/>
                <el-table-column prop="materialCode" label="原厂配件代码" width="150"/>
                <el-table-column prop="equimentSource" label="供应单位" width="150"/>
                <el-table-column prop="equimentManu" label="生产厂家" width="100"/>
                <el-table-column prop="materialModel" label="规格型号" width="150"/>
                <el-table-column prop="materialUnit" label="计量单位" width="100"/>
                <el-table-column prop="warePlace" label="存放地" width="100"/>
                <el-table-column prop="wareTime" label="入库时间" width="100"/>
                <el-table-column prop="stockNum" label="库存" width="100"/>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button type="warning" @click="make_qrcode(scope.row)"> 生成二维码</el-button>
                        <el-button type="success" @click="printerQrcode(scope.row)"> 打印二维码</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!--  分页-->
        <div style="float: right;margin-top: 20px;">
            <el-pagination v-model:currentPage="pagenum"
                           :small="true"
                           background layout="prev, pager, next" :total="totals">
            </el-pagination>
        </div>
        <!--  二维码弹窗-->
        <div>
            <el-dialog
                    v-model="dialogQrcode"
                    title="材料二维码"
                    width="20%"
                    :before-close="handleCloseQrcode">
                <div>
                    {{ qr_material_name }}
                </div>
                <el-divider/>
                <div style="display: flex;justify-content: space-between">
                    <!--材料信息-->
                    <div>
                        <!--规格型号-->
                        <div style="margin-bottom: 5%">
                            <p>规格型号</p>
                            <p>{{ qr_material_model }}</p>
                        </div>
                        <!--计量单位-->
                        <div style="margin-top: 5%">
                            <p>计量单位</p>
                            <p>{{ qr_material_unit }}</p>
                        </div>
                    </div>
                    <!--  二维码-->
                    <div style="margin-right: 20%">
                        <div id="qrcode" ref="qrCodeUrl"></div>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import QRCode from "qrcodejs2";
import {Encrypt} from "@/utils/secret";
import {
    commitJob,
    DrawLableLine, DrawLableQrCode,
    DrawLableText,
    getAllPrinters, getInstance,
    InitDrawingBoard,
    initSdk,
    selectPrinter, startJob
} from "@/utils/jcPrinterSdk_api_third";

export default {
    computed: {
        ...mapGetters(['GET_BDST'])
    },
    name: "index",
    inject: ['reload'],
    data() {
        return {
            ware_qrcodes: [],
            pagenum: 1,
            totals: 0,
            dialogQrcode: false,
            qr_material_id: '',
            qr_material_unit: '',
            qr_material_model: '',
            qr_material_name: '',
            initSdkParam: {//初始化数据
                "fontDir": "",
            },

            InitDrawingBoardParam: {
                "width": 70,
                "height": 100,
                "rotate": 0,
                "path": "ZT001.ttf",
                "verticalShift": 0,
                "HorizontalShift": 0
            },
            DrawTextParam: {
                "x": 20.0,//⽔平坐标
                "y": 45.0,//垂直坐标
                "height": 5.0,//⾼度，单位mm
                "width": 80.0,//宽度， 单位mm
                "value": "",//绘制的⽂本（UTF8）
                "fontFamily": "",
                "rotate": 90,//旋转⻆度
                "fontSize": 6.0,//字体⼤⼩
                "textAlignHorizonral": 0,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
                "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
                "letterSpacing": 0.0,//字间距，单位mm
                "lineSpacing": 1.0,//⾏间距，单位mm
            },
            DrawLineParam: {
                "x": 5.0,//⽔平坐标
                "y": 50.0,//垂直坐标
                "height": 1,//⾼度，单位mm
                "width": 97.0,//宽度， 单位mm
                "rotate": 90,//旋转⻆度
                "lineType": 1,//线条类型， 1:实线 2:虚线
                "dashwidth": [1, 1]//虚线段⻓度
            },
            DrawTextParam1: {
                "x": 25.0,//⽔平坐标
                "y": 25.0,//垂直坐标
                "height": 5.0,//⾼度，单位mm
                "width": 40.0,//宽度， 单位mm
                "value": "规格型号",//绘制的⽂本（UTF8）
                "fontFamily": "",
                "rotate": 90,//旋转⻆度
                "fontSize": 6.0,//字体⼤⼩
                "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
                "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
                "letterSpacing": 0.0,//字间距，单位mm
                "lineSpacing": 1.0,//⾏间距，单位mm
            },
            DrawTextParam2: {
                "x": 10.0,//⽔平坐标
                "y": 25.0,//垂直坐标
                "height": 5.0,//⾼度，单位mm
                "width": 50.0,//宽度， 单位mm
                "value": "",//绘制的⽂本（UTF8）
                "fontFamily": "",
                "rotate": 90,//旋转⻆度
                "fontSize": 5.0,//字体⼤⼩
                "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
                "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
                "letterSpacing": 0.0,//字间距，单位mm
                "lineSpacing": 1.0,//⾏间距，单位mm
            },
            DrawTextParam3: {
                "x": 3.0,//⽔平坐标
                "y": 25.0,//垂直坐标
                "height": 5.0,//⾼度，单位mm
                "width": 40.0,//宽度， 单位mm
                "value": "计量单位",//绘制的⽂本（UTF8）
                "fontFamily": "",
                "rotate": 90,//旋转⻆度
                "fontSize": 6.0,//字体⼤⼩
                "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
                "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
                "letterSpacing": 0.0,//字间距，单位mm
                "lineSpacing": 1.0,//⾏间距，单位mm
            },
            DrawTextParam4: {
                "x": -5.0,//⽔平坐标
                "y": 25.0,//垂直坐标
                "height": 5.0,//⾼度，单位mm
                "width": 40.0,//宽度， 单位mm
                "value": "",//绘制的⽂本（UTF8）
                "fontFamily": "",
                "rotate": 90,//旋转⻆度
                "fontSize": 5.0,//字体⼤⼩
                "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
                "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
                "letterSpacing": 0.0,//字间距，单位mm
                "lineSpacing": 1.0,//⾏间距，单位mm
            },
            DrawLableQrCodeParam: {
                "x": 10,
                "y": 55,
                "height": 35,
                "width": 35,
                "value": "",
                "rotate": 0,
                "codeType": 31,
            },
            imgSrc: ''
        }
    },
    created() {
        this.gte_ware_qrcode();
        getInstance();
        setTimeout(this.getPrienter,5000);
    },
    methods: {
        gte_ware_qrcode() {
            this.axios.post('/equimentWare/getWareQrcode', (response) => {
                this.ware_qrcodes = response.obj2
                this.totals = response.obj.pages * 10
            }, {
                bdst: this.GET_BDST,
                pageCount: 1
            })
        },
        make_qrcode(item) {
            this.qr_material_name = item.materialName
            this.qr_material_model = item.materialModel
            this.qr_material_unit = item.materialUnit
            this.qr_material_id = item.id
            setTimeout(() => {
                this.creatQrCode()
            }, 100)
            this.dialogQrcode = true;
        },
        handleCloseQrcode() {
            this.dialogQrcode = false;
            const codeHtml = document.getElementById("qrcode");
            codeHtml.innerHTML = "";
        },
        creatQrCode() {
            const codeHtml = document.getElementById("qrcode");
            codeHtml.innerHTML = "";
            const qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: Encrypt(this.qr_material_id), // 需要转换为二维码的内容
                width: 150,
                height: 150,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            });
        },
        getPrienter() {
            initSdk(this.initSdkParam, function (data) {
                console.log(JSON.parse(JSON.stringify(data)).resultAck.errorCode)
                if (JSON.parse(JSON.stringify(data)).resultAck.errorCode === 0) {
                    getAllPrinters(function (data) {
                        var arrParse = JSON.parse(JSON.stringify(data));
                        console.log('arrParse===========' + data)
                        if (!arrParse.resultAck.result) {
                            return;
                        } else if (JSON.parse(JSON.stringify(data)).resultAck.errorCode === 0) {
                            let allPrinters;
                            allPrinters = JSON.parse(arrParse.resultAck.info)
                            let allPrintersName = Object.keys(allPrinters);
                            let allPrintersValue = Object.values(allPrinters);

                            selectPrinter(allPrintersName[0], parseInt(allPrintersValue[0]), (data => {
                                ElMessage({
                                    message: '打印机连接成功!',
                                    type: 'success',
                                })
                            }))
                        }
                    })
                }
            });

        },

        printerQrcode(item) {
            this.qr_material_name = item.materialName;
            this.qr_material_model = item.materialModel;
            this.qr_material_unit = item.materialUnit;
            this.qr_material_id = item.id;
            const that = this;
            InitDrawingBoard(this.InitDrawingBoardParam, function () {
                that.DrawLableQrCodeParam.value = Encrypt(that.qr_material_id);
                that.DrawTextParam.value = that.qr_material_name;
                that.DrawTextParam2.value = that.qr_material_model;
                that.DrawTextParam4.value = that.qr_material_unit;
                DrawLableText(that.DrawTextParam, function () {
                    DrawLableLine(that.DrawLineParam, function () {
                        DrawLableText(that.DrawTextParam1, function () {
                            DrawLableText(that.DrawTextParam2, function () {
                                DrawLableText(that.DrawTextParam3, function () {
                                    DrawLableText(that.DrawTextParam4, function () {
                                        DrawLableQrCode(that.DrawLableQrCodeParam, function () {
                                            startJob(3, 1, 1, 1, function (data) {
                                                let jsonObj = {"printerImageProcessingInfo": {"printQuantity": 1}};
                                                commitJob(null, JSON.stringify(jsonObj), function (data) {
                                                    console.log(data)
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            });
        },

    },
    watch: {
        pagenum() {
            this.axios.post('/equimentWare/getWareQrcode', (response) => {
                this.ware_qrcodes = response.obj2
                this.totals = response.obj.pages * 10
            }, {
                bdst: this.GET_BDST,
                pageCount: this.pagenum
            })
        }
    }
}
</script>

<style scoped>

</style>
