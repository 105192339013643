<template>
    <div>
        <div>
            <el-input size="small" style="width: 15%" clearable placeholder="请输入材料名称" v-model="material_name"
                      @input="get_qrcode"/>
	        <el-input size="small" style="width: 15%" clearable placeholder="请输入原厂配件码" v-model="material_model"
	                  @input="get_qrcode"/>
            <el-button style="margin-left: 2%" size="small" type="danger" @click="get_qrcode">刷新</el-button>
            <el-button style="margin-left: 5%" type="primary" size="small" @click="getPrienter">连接打印机</el-button>
        </div>
        <!--  列表-->
        <div>
            <el-table :data="equiment_materials" style="width: 100%;min-height: 70vh">
                <el-table-column prop="materialName" label="材料名称" width="180"/>
                <el-table-column prop="materialCode" label="原厂配件代码" width="230"/>
                <el-table-column prop="equimentSource" label="供应单位" width="230"/>
                <el-table-column prop="equimentManu" label="生产厂家" width="230"/>
                <el-table-column prop="materialModel" label="规格型号" width="180"/>
                <el-table-column prop="materialUnit" label="计量单位" width="130"/>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-popconfirm
                                confirm-button-text="确认"
                                cancel-button-text="取消"
                                icon-color="#626AEF"
                                title="是否确认删除?"
                                @confirm="remove_qrcode(scope.row)"
                                @cancel=""
                        >
                            <template #reference>
                                <el-button type="danger"> 删除</el-button>
                            </template>
                        </el-popconfirm>
                        <el-button type="warning" @click="make_qrcode(scope.row)"> 生成二维码</el-button>
                        <el-button type="success" @click="printerQrcode(scope.row)"> 打印二维码</el-button>
	                    <el-button type="primary" @click="updata_material(scope.row)"> 修改信息</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!--  分页-->
        <div style="float: right;margin-top: 20px;">
            <el-pagination v-model:currentPage="pagenum"
                           :small="true"
                           background layout="prev, pager, next" :total="totals">
            </el-pagination>
        </div>
        <!--  二维码弹窗-->
        <div>
            <el-dialog
                    v-model="dialogQrcode"
                    title="材料二维码"
                    width="20%"
                    :before-close="handleCloseQrcode">
                <div>
                    {{ qr_material_name }}
                </div>
                <el-divider/>
                <div style="display: flex;justify-content: space-between">
                    <!--材料信息-->
                    <div>
                        <!--规格型号-->
                        <div style="margin-bottom: 5%">
                            <p>规格型号</p>
                            <p>{{ qr_material_model }}</p>
                        </div>
                        <!--计量单位-->
                        <div style="margin-top: 5%">
                            <p>计量单位</p>
                            <p>{{ qr_material_unit }}</p>
                        </div>
                    </div>
                    <!--  二维码-->
                    <div style="margin-right: 20%">
                        <div id="qrcode" ref="qrCodeUrl"></div>
                    </div>
                </div>
            </el-dialog>
        </div>
	  <!--  修改信息弹窗-->
	    <div>
		    <el-dialog
			    v-model="updataDialogVisible"
			    title="修改材料信息"
			    width="60%"
			    :before-close="handleCloseUpdate"
		    >
			    <div>
				    <el-form :model="form" label-width="150px">
					    <el-form-item label="材料名称">
						    <el-input v-model="form.material_name" style="width: 50%"/>
					    </el-form-item>
					    <el-form-item label="原厂配件代码">
						    <el-input v-model="form.material_code" style="width: 50%"/>
					    </el-form-item>
					    <el-form-item label="供应单位">
						    <el-input v-model="form.equiment_source" style="width: 50%"/>
					    </el-form-item>
					    <el-form-item label="生产厂家">
						    <el-input v-model="form.equiment_manu" style="width: 50%"/>
					    </el-form-item>
					    <el-form-item label="规格型号">
						    <el-input v-model="form.material_model" style="width: 50%"/>
					    </el-form-item>
					    <el-form-item label="计量单位">
						    <el-input v-model="form.material_unit" style="width: 50%"/>
					    </el-form-item>
				    </el-form>
			    </div>
			    <template #footer>
                    <span class="dialog-footer">
                        <el-button type="primary" @click="updata_confirm">确认修改</el-button>
												<el-button @click="handleCloseUpdate">取消</el-button>
                    </span>
			    </template>
		    </el-dialog>
	    </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import QRCode from "qrcodejs2";
import {Encrypt} from "@/utils/secret";
import {ElMessage} from "element-plus";
import {
    commitJob,
    DrawLableLine, DrawLableQrCode,
    DrawLableText,
    getAllPrinters, getInstance,
    InitDrawingBoard,
    initSdk,
    selectPrinter, startJob
} from "@/utils/jcPrinterSdk_api_third";

export default {
    name: "ware_issue_manage",
    computed: {
        ...mapGetters(['GET_BDST'])
    },
    inject: ['reload'],
    data() {
        return {
	          material_model: '',
            material_name: '',
            equiment_materials: [],
            totals: 0,
            pagenum: 1,
            dialogQrcode: false,
            qr_material_id: '',
            qr_material_unit: '',
            qr_material_model: '',
            qr_material_name: '',
	          updataDialogVisible: false,
	          form:{
							material_name: '',
		          material_code: '',
		          material_model: '',
		          material_unit: '',
		          equiment_source: '',
		          equiment_manu: '',
		          id: ''
	          },
            initSdkParam: {//初始化数据
                "fontDir": "",
            },

            InitDrawingBoardParam: {
                "width": 70,
                "height": 100,
                "rotate": 0,
                "path": "ZT001.ttf",
                "verticalShift": 0,
                "HorizontalShift": 0
            },
            DrawTextParam: {
                "x": 20.0,//⽔平坐标
                "y": 45.0,//垂直坐标
                "height": 5.0,//⾼度，单位mm
                "width": 80.0,//宽度， 单位mm
                "value": "",//绘制的⽂本（UTF8）
                "fontFamily": "",
                "rotate": 90,//旋转⻆度
                "fontSize": 6.0,//字体⼤⼩
                "textAlignHorizonral": 0,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
                "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
                "letterSpacing": 0.0,//字间距，单位mm
                "lineSpacing": 1.0,//⾏间距，单位mm
            },
            DrawLineParam: {
                "x": 5.0,//⽔平坐标
                "y": 50.0,//垂直坐标
                "height": 1,//⾼度，单位mm
                "width": 97.0,//宽度， 单位mm
                "rotate": 90,//旋转⻆度
                "lineType": 1,//线条类型， 1:实线 2:虚线
                "dashwidth": [1, 1]//虚线段⻓度
            },
            DrawTextParam1: {
                "x": 25.0,//⽔平坐标
                "y": 25.0,//垂直坐标
                "height": 5.0,//⾼度，单位mm
                "width": 40.0,//宽度， 单位mm
                "value": "规格型号",//绘制的⽂本（UTF8）
                "fontFamily": "",
                "rotate": 90,//旋转⻆度
                "fontSize": 6.0,//字体⼤⼩
                "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
                "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
                "letterSpacing": 0.0,//字间距，单位mm
                "lineSpacing": 1.0,//⾏间距，单位mm
            },
            DrawTextParam2: {
                "x": 10.0,//⽔平坐标
                "y": 25.0,//垂直坐标
                "height": 5.0,//⾼度，单位mm
                "width": 50.0,//宽度， 单位mm
                "value": "",//绘制的⽂本（UTF8）
                "fontFamily": "",
                "rotate": 90,//旋转⻆度
                "fontSize": 5.0,//字体⼤⼩
                "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
                "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
                "letterSpacing": 0.0,//字间距，单位mm
                "lineSpacing": 1.0,//⾏间距，单位mm
            },
            DrawTextParam3: {
                "x": 3.0,//⽔平坐标
                "y": 25.0,//垂直坐标
                "height": 5.0,//⾼度，单位mm
                "width": 40.0,//宽度， 单位mm
                "value": "计量单位",//绘制的⽂本（UTF8）
                "fontFamily": "",
                "rotate": 90,//旋转⻆度
                "fontSize": 6.0,//字体⼤⼩
                "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
                "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
                "letterSpacing": 0.0,//字间距，单位mm
                "lineSpacing": 1.0,//⾏间距，单位mm
            },
            DrawTextParam4: {
                "x": -5.0,//⽔平坐标
                "y": 25.0,//垂直坐标
                "height": 5.0,//⾼度，单位mm
                "width": 40.0,//宽度， 单位mm
                "value": "",//绘制的⽂本（UTF8）
                "fontFamily": "",
                "rotate": 90,//旋转⻆度
                "fontSize": 5.0,//字体⼤⼩
                "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
                "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
                "letterSpacing": 0.0,//字间距，单位mm
                "lineSpacing": 1.0,//⾏间距，单位mm
            },
            DrawLableQrCodeParam: {
                "x": 10,
                "y": 55,
                "height": 35,
                "width": 35,
                "value": "",
                "rotate": 0,
                "codeType": 31,
            },
            imgSrc: '',
	        del_ids: []
        }
    },
    created() {
        this.get_qrcode();
        getInstance();
        setTimeout(this.getPrienter,5000);
    },
    methods: {
			// 修改信息
	    handleCloseUpdate(){
				this.updataDialogVisible = false
		    this.form = {}
	    },
	    updata_material(item){
		    this.form.material_name = item.materialName
		    this.form.material_model = item.materialModel
		    this.form.material_unit = item.materialUnit
		    this.form.material_code = item.materialCode
		    this.form.equiment_manu = item.equimentManu
		    this.form.equiment_source = item.equimentSource
		    this.form.id = item.id
		    this.updataDialogVisible = true
	    },
	    updata_confirm(){
				this.axios.post('/equimentQrcode/updataById', (response) => {
					if (response.obj){
						ElMessage.success('修改成功!')
						this.updataDialogVisible = false
						this.reload()
					}else {
						ElMessage.error('修改失败，请联系科信部!')
					}
				},{
					id: this.form.id,
					material_name: this.form.material_name,
					material_model: this.form.material_model,
					material_unit: this.form.material_unit,
					material_code: this.form.material_code,
					equiment_source: this.form.equiment_source,
					equiment_manu: this.form.equiment_manu,
				})
	    },

        get_qrcode() {
            this.axios.post('/equimentQrcode/getQrcodeByBdst', (response) => {
							console.log(response.obj.pages)
                this.equiment_materials = response.obj.records
                this.totals = response.obj.pages * 10
            }, {
                bdst: this.GET_BDST,
                material_name: this.material_name,
	              material_model: this.material_model,
                pageCount: 1
            })
        },
        // change_material() {
        //     this.axios.post('/equimentQrcode/getQrcodeByBdst', (response) => {
        //         this.equiment_materials = response.obj.records
        //         this.totals = response.obj.pages * 10
        //     }, {
        //         bdst: this.GET_BDST,
        //         material_name: this.material_name,
	      //         material_model: this.material_model,
        //         pageCount: 1
        //     })
        // },
        creatQrCode() {
            const qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: Encrypt(this.qr_material_id), // 需要转换为二维码的内容
                //text: 'http://182.92.228.212/czsc/#/mobilsel?qrcodeid=' + this.uuid, // 需要转换为二维码的内容
                width: 150,
                height: 150,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            });
        },
        make_qrcode(item) {
            this.qr_material_name = item.materialName
            this.qr_material_model = item.materialModel
            this.qr_material_unit = item.materialUnit
            this.qr_material_id = item.id
            setTimeout(() => {
                this.creatQrCode()
            }, 100)
            this.dialogQrcode = true;
        },
        handleCloseQrcode() {
            this.dialogQrcode = false;
            const codeHtml = document.getElementById("qrcode");
            codeHtml.innerHTML = "";
        },
        remove_qrcode(item) {
            this.axios.post('/equimentQrcode/removeQrcode', (response) => {
                if (response.obj) {
                    ElMessage({
                        message: '删除成功',
                        type: 'success'
                    })
                    this.reload()
                } else {
                    ElMessage.error('删除失败，请联系科信部!')
                }
            }, {
                uuid: item.id
            })
        },
        getPrienter() {
            initSdk(this.initSdkParam, function (data) {
                console.log(JSON.parse(JSON.stringify(data)).resultAck.errorCode)
                if (JSON.parse(JSON.stringify(data)).resultAck.errorCode === 0) {
                    getAllPrinters(function (data) {
                        var arrParse = JSON.parse(JSON.stringify(data));
                        console.log('arrParse===========' + data)
                        if (!arrParse.resultAck.result) {
                            return;
                        } else if (JSON.parse(JSON.stringify(data)).resultAck.errorCode === 0) {
                            let allPrinters;
                            allPrinters = JSON.parse(arrParse.resultAck.info)
                            let allPrintersName = Object.keys(allPrinters);
                            let allPrintersValue = Object.values(allPrinters);

                            selectPrinter(allPrintersName[0], parseInt(allPrintersValue[0]), (data => {
                                ElMessage({
                                    message: '打印机连接成功!',
                                    type: 'success',
                                })
                            }))
                        }
                    })
                }
            });

        },
        printerQrcode(item) {
            this.qr_material_name = item.materialName;
            this.qr_material_model = item.materialModel;
            this.qr_material_unit = item.materialUnit;
            this.qr_material_id = item.id;
            const that = this;
            InitDrawingBoard(this.InitDrawingBoardParam, function () {
                that.DrawLableQrCodeParam.value = Encrypt(that.qr_material_id);
                that.DrawTextParam.value = that.qr_material_name;
                that.DrawTextParam2.value = that.qr_material_model;
                that.DrawTextParam4.value = that.qr_material_unit;
                DrawLableText(that.DrawTextParam, function () {
                    DrawLableLine(that.DrawLineParam, function () {
                        DrawLableText(that.DrawTextParam1, function () {
                            DrawLableText(that.DrawTextParam2, function () {
                                DrawLableText(that.DrawTextParam3, function () {
                                    DrawLableText(that.DrawTextParam4, function () {
                                        DrawLableQrCode(that.DrawLableQrCodeParam, function () {
                                            startJob(3, 1, 1, 1, function (data) {
                                                let jsonObj = {"printerImageProcessingInfo": {"printQuantity": 1}};
                                                commitJob(null, JSON.stringify(jsonObj), function (data) {
                                                    console.log(data)
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            });
        },
    },
    watch: {
        pagenum() {
            this.axios.post('/equimentQrcode/getQrcodeByBdst', (response) => {
                this.equiment_materials = response.obj.records
                this.totals = response.obj.pages * 10
            }, {
                bdst: this.GET_BDST,
                material_name: this.material_name,
	              material_model: this.material_model,
                pageCount: this.pagenum
            })
        }
    }
}
</script>

<style scoped>

</style>
