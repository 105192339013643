<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">工区材料管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--  基础信息管理-->
    <div id="forms_div2">
      <el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="材料库管理" name="a"><Equiment_storehouse_up v-if="a"></Equiment_storehouse_up></el-tab-pane>
        <el-tab-pane label="材料二维码管理" name="b"><Ware_issue_manage v-if="b"></Ware_issue_manage></el-tab-pane>
        <el-tab-pane label="入库二维码管理" name="d"><Equiment_ware_qrcode v-if="d"></Equiment_ware_qrcode></el-tab-pane>
        <el-tab-pane label="库房管理" name="c"><Equiment_place v-if="c"></Equiment_place></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import Equiment_storehouse from "@/views/equiment/equiment_ware_issue/equiment_storehouse";
import Ware_issue_manage from "@/views/equiment/equiment_ware_issue/ware_issue_manage";
import Equiment_place from "@/views/equiment/equiment_ware_issue/equiment_place";
import Equiment_storehouse_up from "@/views/equiment/equiment_ware_issue/equiment_storehouse_up";
import Equiment_ware_qrcode from "@/views/equiment/equiment_ware_issue/equiment_ware_qrcode";

export default {
  name: "equiment_ware_issue",
  components: {Header, Equiment_storehouse, Ware_issue_manage, Equiment_place, Equiment_storehouse_up, Equiment_ware_qrcode},
  data(){
    return{
      activeName: 'a',
	    a: true,
	    b: false,
	    c: false,
	    d: false
    }
  },
  created() {
	  if (!this.$route.params.key){
		  this.$router.push("/manage")
	  }
    if (sessionStorage.getItem('equiment_ware_issue_names')){
      this.activeName = sessionStorage.getItem('equiment_ware_issue_names');
	    if (this.activeName === 'a'){
		    this.a = true
		    this.b = false
		    this.c = false
		    this.d = false
	    }
	    if (this.activeName === 'b'){
		    this.b = true
		    this.a = false
		    this.c = false
		    this.d = false
	    }
	    if (this.activeName === 'c'){
		    this.c = true
		    this.b = false
		    this.a = false
		    this.d = false
	    }
	    if (this.activeName === 'd'){
		    this.d = true
		    this.b = false
		    this.c = false
		    this.a = false
	    }
    }
  },
  methods:{
    handleClick(tab, event){
      // 点击tab后触发事件，修改显示页面，将状态保存在sessionStorage里面
      sessionStorage.setItem('equiment_ware_issue_names', tab.props.name)
	    if (tab.props.name === 'a'){
		    this.a = true
		    this.b = false
		    this.c = false
		    this.d = false
	    }
	    if (tab.props.name === 'b'){
		    this.b = true
		    this.a = false
		    this.c = false
		    this.d = false
	    }
	    if (tab.props.name === 'c'){
		    this.c = true
		    this.b = false
		    this.a = false
		    this.d = false
	    }
	    if (tab.props.name === 'd'){
		    this.d = true
		    this.b = false
		    this.c = false
		    this.a = false
	    }
    },
    reall(){
      sessionStorage.removeItem('equiment_ware_issue_names');
    },
  }
}
</script>

<style scoped>
#admindiv1 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
#forms_div2{
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}
</style>
