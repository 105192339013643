<template>
  <div style="width: 100%;">
    <div style="display: flex;width: 100%">

      <el-select v-model="type" placeholder="请选择查看类型" size="small"
                 style="width: 15%" @change="get_all_palces" clearable>
        <el-option
            v-for="item in types"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      <el-button style="margin-left: 2%" type="primary" @click="diaSaveEquimentPlace = true"> 新增 </el-button>
    </div>
  <!--  库房列表-->
    <div>
      <el-table :data="equiment_places" style="width: 100%">
        <el-table-column prop="bdst" label="所属工区" width="180"/>
        <el-table-column prop="warePlace" label="库房名称" width="280"/>
        <el-table-column prop="c" label="类型" width="280">
          <template #default="scope">
            <span v-if="scope.row.type === 0">库房</span>
            <span v-if="scope.row.type === 1">使用单位</span>
            <span v-if="scope.row.type === 2">使用部位</span>
            <span v-if="scope.row.type === 3">设备管理码</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-popconfirm
                confirm-button-text="确认"
                cancel-button-text="取消"
                icon-color="#626AEF"
                title="是否确认删除?"
                @confirm="remove_equiment_place(scope.row)"
                @cancel=""
            >
              <template #reference>
                <el-button type="danger"> 删除 </el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  分页-->
    <div style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum"
                     :small="true"
                     background layout="prev, pager, next" :total="totals">
      </el-pagination>
    </div>
  <!--  新增-->
    <div>
      <el-dialog
          v-model="diaSaveEquimentPlace"
          title="新增"
          width="30%"
          :before-close="handleCloseEquiment">
        <div>
          <div style="margin-left: 30%;margin-top: 5%">
            <div v-if="GET_BDST === '21,56'" style="display: flex">
              <el-tag>工区</el-tag>
              <el-select v-model="bdst" placeholder="请选择工区" size="small"
                         style="width: 60%">
                <el-option
                    v-for="item in bdsts"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>类型</el-tag>
              <el-select v-model="type1" placeholder="请选择新增类型" size="small"
                         style="width: 70%" @change="get_equiment_name" clearable>
                <el-option
                    v-for="item in types"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </div>
            <div v-if="this.type1 === '3'" style="display: flex;margin-top: 2%">
              <el-tag>设备</el-tag>
              <el-select v-model="equiment_name" placeholder="请选择设备" size="small"
                         style="width: 70%" clearable>
                <el-option
                    v-for="item in equiment_names"
                    :key="item.id"
                    :label="item.equipmentName + '---' + item.equipmentModel"
                    :value="item.id"
                />
              </el-select>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>名称</el-tag>
              <el-input v-model="save_place" size="small" style="width: 70%;" placeholder="请输入名称"></el-input>
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary " @click="save_equiment_place">提交</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ElMessage} from "element-plus";

export default {
  name: "equiment_place",
  computed: {
    ...mapGetters(['GET_BDST'])
  },
  inject: ['reload'],
  data(){
    return{
      equiment_places: [],
      bdst: '',
      bdsts: [
        {
          value: '21',
          label: '二标一工区'
        },
        {
          value: '56',
          label: '五标六工区'
        }
      ],
      type: '',
      types: [
        {
          value: '0',
          label: '库房'
        },
        {
          value: '1',
          label: '使用单位'
        },
        {
          value: '2',
          label: '使用部位'
        },
        {
          value: '3',
          label: '设备管理码'
        }
      ],
      add_place: false,
      save_place: '',
      totals: 0,
      pagenum: 1,
      diaSaveEquimentPlace: false,
      type1: '',
      equiment_name: '',
      equiment_names: []
    }
  },
  created() {
    this.get_all_palces()
  },
  methods:{
    get_all_palces(){
      this.axios.post('/equimentPlace/getAllPlace', (response) => {
        this.equiment_places = response.obj.records
        this.totals = response.obj.pages * 10
      },{
        bdst: this.GET_BDST,
        pageCount: 1,
        type: this.type
      })
    },
    remove_equiment_place(item){
      this.axios.post('/equimentPlace/removePlace', (response) => {
        if (response.obj){
          ElMessage({
            message: '删除成功',
            type: 'success'
          })
          this.reload()
        }else {
          ElMessage.error('删除失败，请联系科信部!')
        }
      },{
        id: item.id
      })
    },
    handleCloseEquiment(){
      this.save_place = ''
      this.type1 = ''
      this.diaSaveEquimentPlace = false
      this.reload()
    },
    save_equiment_place(){
      let flag = false
      if (this.GET_BDST === '1') {
        ElMessage.error('请切换到工区的账号进行添加!')
      } else if (this.GET_BDST !== '21,56') {
        this.bdst = this.GET_BDST
        flag = true
      } else if (this.type === ''){
        ElMessage.error('请选择添加类型!')
      }
      if (flag){
        this.axios.post('/equimentPlace/saveEquimentPlace', (response) => {
          if (response.obj) {
            ElMessage({
              message: '添加成功',
              type: 'success'
            })
            this.save_place = ''
            this.type1 = ''
          } else {
            ElMessage.error('添加失败，请联系科信部!')
          }
        },{
          bdst: this.bdst,
          place: this.save_place,
          type: this.type1,
          equiment_id: this.equiment_name
        })
      }
    },
    get_equiment_name(){
      if (this.type1 === '3'){
        this.axios.get('/equimentBasis/getAllEquiment', (response) => {
         this.equiment_names = response.obj
        })
      }
    }
  },
  watch:{
    pagenum(){
      this.axios.post('/equimentPlace/getAllPlace', (response) => {
        this.equiment_places = response.obj.records
        this.totals = response.obj.pages * 10
      },{
        bdst: this.GET_BDST,
        pageCount: this.pagenum
      })
    }
  }
}
</script>

<style scoped>

</style>
